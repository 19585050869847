const SPOOKY_TOKEN_LIST = [
  {
    name: 'Aave',
    symbol: 'AAVE',
    address: '0x6a07A792ab2965C72a5B8088d3a069A7aC3a993B',
    chainId: 250,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12645/large/AAVE.png',
  },
  {
    name: 'Badger',
    symbol: 'BADGER',
    address: '0x753fbc5800a8C8e3Fb6DC6415810d627A387Dfc9',
    chainId: 250,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13287/large/badger_dao_logo.jpg',
  },
  {
    name: 'Band Protocol',
    symbol: 'BAND',
    address: '0x46E7628E8b4350b2716ab470eE0bA1fa9e76c6C5',
    chainId: 250,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/9545/large/band-protocol.png',
  },
  {
    name: 'SpookySwap',
    symbol: 'BOO',
    address: '0x841FAD6EAe12c286d1Fd18d1d525DFfA75C7EFFE',
    chainId: 250,
    decimals: 18,
    logoURI: 'https://assets.spookyswap.finance/tokens/BOO.png',
  },
  {
    name: 'Cover Protocol',
    symbol: 'COVER',
    address: '0xB01E8419d842beebf1b70A7b5f7142abbaf7159D',
    chainId: 250,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13563/large/1_eWBbDaqpxXqt7WYPSP4qSw.jpeg',
  },
  {
    name: 'Cream',
    symbol: 'CREAM',
    address: '0x657A1861c15A3deD9AF0B6799a195a249ebdCbc6',
    chainId: 250,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/11976/large/Cream.png',
  },
  {
    name: 'Curve DAO',
    symbol: 'CRV',
    address: '0x1E4F97b9f9F913c46F1632781732927B9019C68b',
    chainId: 250,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12124/large/Curve.png',
  },
  {
    name: 'Dai Stablecoin',
    symbol: 'DAI',
    address: '0x8D11eC38a3EB5E956B052f67Da8Bdc9bef8Abf3E',
    chainId: 250,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/9956/large/dai-multi-collateral-mcd.png',
  },
  {
    name: 'Frapped USDT',
    symbol: 'fUSDT',
    address: '0x049d68029688eAbF473097a2fC38ef61633A3C7A',
    chainId: 250,
    decimals: 6,
    logoURI: 'https://assets.coingecko.com/coins/images/325/large/Tether-logo.png',
  },
  {
    name: 'Graviton',
    symbol: 'GTON',
    address: '0xC1Be9a4D5D45BeeACAE296a7BD5fADBfc14602C4',
    chainId: 250,
    decimals: 18,
    logoURI: 'https://assets.spookyswap.finance/tokens/GTON.png',
  },
  {
    name: 'IceToken',
    symbol: 'ICE',
    address: '0xf16e81dce15B08F326220742020379B855B87DF9',
    chainId: 250,
    decimals: 18,
    logoURI: 'https://zapper.fi/images/ICE-icon.png',
  },
  {
    name: 'ChainLink',
    symbol: 'LINK',
    address: '0xb3654dc3D10Ea7645f8319668E8F54d2574FBdC8',
    chainId: 250,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/877/large/chainlink-new-logo.png',
  },
  {
    name: 'Synthetix Network',
    symbol: 'SNX',
    address: '0x56ee926bD8c72B2d5fa1aF4d9E4Cbb515a1E3Adc',
    chainId: 250,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/3406/large/SNX.png',
  },
  {
    name: 'Sushi',
    symbol: 'SUSHI',
    address: '0xae75A438b2E0cB8Bb01Ec1E1e376De11D44477CC',
    chainId: 250,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12271/large/512x512_Logo_no_chop.png',
  },
  {
    name: 'USD Coin',
    symbol: 'USDC',
    address: '0x04068DA6C83AFCFA0e13ba15A6696662335D5B75',
    chainId: 250,
    decimals: 6,
    logoURI: 'https://assets.coingecko.com/coins/images/6319/large/USD_Coin_icon.png',
  },
  {
    name: 'Wrapped Bitcoin',
    symbol: 'wBTC',
    address: '0x321162Cd933E2Be498Cd2267a90534A804051b11',
    chainId: 250,
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/7598/large/wrapped_bitcoin_wbtc.png',
  },
  {
    name: 'Wrapped Ether',
    symbol: 'wETH',
    address: '0x74b23882a30290451A17c44f4F05243b6b58C76d',
    chainId: 250,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/279/large/ethereum.png',
  },
  {
    name: 'Wrapped FTM',
    symbol: 'wFTM',
    address: '0x21be370D5312f44cB42ce377BC9b8a0cEF1A4C83',
    chainId: 250,
    decimals: 18,
    logoURI: 'https://assets.spookyswap.finance/coins/0x21be370D5312f44cB42ce377BC9b8a0cEF1A4C83.png',
  },
  {
    name: 'yearn.finance',
    symbol: 'YFI',
    address: '0x29b0Da86e484E1C0029B56e817912d778aC0EC69',
    chainId: 250,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/11849/large/yfi-192x192.png',
  },
  {
    name: 'ZooCoin',
    symbol: 'ZOO',
    address: '0x09e145A1D53c0045F41aEEf25D8ff982ae74dD56',
    chainId: 250,
    decimals: 0,
    logoURI: 'https://assets.spookyswap.finance/tokens/ZOO.png',
  },
  {
    name: 'Frax Share',
    symbol: 'FXS',
    address: '0x82F8Cb20c14F134fe6Ebf7aC3B903B2117aAfa62',
    chainId: 250,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13423/large/frax_share.png',
  },
  {
    name: 'MMToken',
    symbol: 'MM',
    address: '0xbFaf328Fe059c53D936876141f38089df0D1503D',
    chainId: 250,
    decimals: 18,
    logoURI: 'https://assets.spookyswap.finance/tokens/MM.png',
  },
  {
    name: 'Binance Coin',
    symbol: 'BNB',
    address: '0xD67de0e0a0Fd7b15dC8348Bb9BE742F3c5850454',
    chainId: 250,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/825/large/binance-coin-logo.png',
  },
  {
    name: 'Woofy',
    symbol: 'WOOFY',
    address: '0xD0660cD418a64a1d44E9214ad8e459324D8157f1',
    chainId: 250,
    decimals: 12,
    logoURI: 'https://assets.spookyswap.finance/tokens/WOOFY.png',
  },
  {
    name: 'Anyswap',
    symbol: 'ANY',
    address: '0xdDcb3fFD12750B45d32E084887fdf1aABAb34239',
    chainId: 250,
    decimals: 18,
    logoURI: 'https://raw.githubusercontent.com/anyswap/Brand-assets/master/logo/c-128-color-2.png',
  },
  {
    name: 'Beefy.Finance',
    symbol: 'BIFI',
    address: '0xd6070ae98b8069de6B494332d1A1a81B6179D960',
    chainId: 250,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12704/large/token.png',
  },
  {
    name: 'TOMB',
    symbol: 'TOMB',
    address: '0x6c021Ae822BEa943b2E66552bDe1D2696a53fbB7',
    chainId: 250,
    decimals: 18,
    logoURI: 'https://assets.spookyswap.finance/tokens/TOMB.png',
  },
  {
    name: 'TSHARE',
    symbol: 'TSHARE',
    address: '0x4cdF39285D7Ca8eB3f090fDA0C069ba5F4145B37',
    chainId: 250,
    decimals: 18,
    logoURI: 'https://assets.spookyswap.finance/tokens/TSHARE.png',
  },
  {
    name: 'BOMB',
    symbol: 'BOMB',
    address: '0x8503eb4A136bDBeB323E37Aa6e0FA0C772228378',
    chainId: 250,
    decimals: 0,
    logoURI: 'https://assets.spookyswap.finance/tokens/BOMB.png',
  },
  {
    name: 'Boo MirrorWorld',
    symbol: 'xBOO',
    address: '0xa48d959AE2E88f1dAA7D5F611E01908106dE7598',
    chainId: 250,
    decimals: 18,
    logoURI: 'https://assets.spookyswap.finance/tokens/xBOO.png',
  },
  {
    name: 'Smart Token',
    symbol: 'SMART',
    address: '0x34D33dc8Ac6f1650D94A7E9A972B47044217600b',
    chainId: 250,
    decimals: 18,
    logoURI: 'https://assets.spookyswap.finance/tokens/SMART.png',
  },
  {
    name: 'aUSD',
    symbol: 'aUSD',
    address: '0x41e3dF7f716aB5AF28c1497B354D79342923196a',
    chainId: 250,
    decimals: 18,
    logoURI: 'https://aquarius.fi/assets/200/AUSD200-t.png',
  },
  {
    name: 'Fantom USD',
    symbol: 'fUSD',
    address: '0xAd84341756Bf337f5a0164515b1f6F993D194E1f',
    chainId: 250,
    decimals: 18,
    logoURI: 'https://assets.spookyswap.finance/tokens/fUSD.png',
  },
  {
    name: 'Magic Internet Money',
    symbol: 'MIM',
    address: '0x82f0B8B456c1A451378467398982d4834b6829c1',
    chainId: 250,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/16786/large/mimlogopng.png',
  },
  {
    name: 'ShadeToken',
    symbol: 'SHADE',
    address: '0x3A3841f5fa9f2c283EA567d5Aeea3Af022dD2262',
    chainId: 250,
    decimals: 18,
    logoURI: 'https://assets.spookyswap.finance/tokens/SHADE.png',
  },
  {
    name: 'AtariToken',
    symbol: 'ATRI',
    address: '0x818ec0A7Fe18Ff94269904fCED6AE3DaE6d6dC0b',
    chainId: 250,
    decimals: 0,
    logoURI: 'https://assets.coingecko.com/coins/images/12992/large/atari.png',
  },
  {
    name: 'Cryptokek.com',
    symbol: 'KEK',
    address: '0x627524d78B4fC840C887ffeC90563c7A42b671fD',
    chainId: 250,
    decimals: 18,
    logoURI: 'https://assets.spookyswap.finance/tokens/KEK.png',
  },
  {
    name: 'YEL Token',
    symbol: 'YEL',
    address: '0xD3b71117E6C1558c1553305b44988cd944e97300',
    chainId: 250,
    decimals: 18,
    logoURI: 'https://assets.spookyswap.finance/tokens/YEL.png',
  },
  {
    name: 'iFUSD',
    symbol: 'iFUSD',
    address: '0x9fC071cE771c7B27b7d9A57C32c0a84c18200F8a',
    chainId: 250,
    decimals: 18,
    logoURI: 'https://assets.spookyswap.finance/tokens/iFUSD.png',
  },
  {
    name: 'Scream',
    symbol: 'SCREAM',
    address: '0xe0654C8e6fd4D733349ac7E09f6f23DA256bF475',
    chainId: 250,
    decimals: 18,
    logoURI: 'https://assets.spookyswap.finance/tokens/SCREAM.png',
  },
  {
    name: 'Tarot',
    symbol: 'TAROT',
    address: '0xC5e2B037D30a390e62180970B3aa4E91868764cD',
    chainId: 250,
    decimals: 18,
    logoURI: 'https://assets.spookyswap.finance/tokens/TAROT.png',
  },
  {
    name: 'BABYBOO',
    symbol: 'BABYBOO',
    address: '0x471762A7017A5B1A3e931F1A97aa03Ef1E7F4A73',
    chainId: 250,
    decimals: 18,
    logoURI: 'https://assets.spookyswap.finance/tokens/BABYBOO.png',
  },
  {
    name: 'Death',
    symbol: 'Death',
    address: '0xeaF45B62d9d0Bdc1D763baF306af5eDD7C0d7e55',
    chainId: 250,
    decimals: 9,
    logoURI: 'https://assets.spookyswap.finance/tokens/DEATH.png',
  },
  {
    name: 'The Eye of Truth',
    symbol: 'EYE',
    address: '0x496e1693A7B162c4f0Cd6a1792679cC48EcbCC8d',
    chainId: 250,
    decimals: 18,
    logoURI: 'https://assets.spookyswap.finance/tokens/EYE.png',
  },
  {
    name: 'Munny',
    symbol: 'MUNNY',
    address: '0x195FE0c899434fB47Cd6c1A09ba9DA56A1Cca12C',
    chainId: 250,
    decimals: 18,
    logoURI: 'https://assets.spookyswap.finance/tokens/MUNNY.png',
    bridgeId: '',
    nativeChain: '',
  },
  {
    name: 'PenniToken',
    symbol: 'PENNI',
    address: '0x47d0d625638b56084e76b8720475d175d171af9A',
    chainId: 250,
    decimals: 18,
    logoURI: 'https://assets.spookyswap.finance/tokens/PENNI.png',
    bridgeId: '',
    nativeChain: '',
  },
  {
    name: 'Fantums of Opera Token',
    symbol: 'FOO',
    address: '0xFbc3c04845162F067A0B6F8934383E63899c3524',
    chainId: 250,
    decimals: 18,
    logoURI: 'https://assets.spookyswap.finance/tokens/FOO.png',
  },
  {
    name: 'SteakToken',
    symbol: 'STEAK',
    address: '0x05848B832E872d9eDd84AC5718D58f21fD9c9649',
    chainId: 250,
    decimals: 18,
    logoURI: 'https://assets.spookyswap.finance/tokens/STEAK.png',
  },
  {
    name: 'Totem Finance',
    symbol: 'TOTEM',
    address: '0x31a37aedC0C18AA139e120e1CDC673BbB2063e6f',
    chainId: 250,
    decimals: 18,
    logoURI: 'https://assets.spookyswap.finance/tokens/TOTEM.png',
  },
  {
    name: 'OliveCash Token',
    symbol: 'fOLIVE',
    address: '0xA9937092c4E2B0277C16802Cc8778D252854688A',
    chainId: 250,
    decimals: 18,
    logoURI: 'https://assets.spookyswap.finance/tokens/OLIVE.png',
  },
  {
    name: 'Syfin',
    symbol: 'SYF',
    address: '0x1BCF4DC879979C68fA255f731FE8Dcf71970c9bC',
    chainId: 250,
    decimals: 18,
    logoURI: 'https://assets.spookyswap.finance/tokens/SYF.png',
  },
  {
    name: 'FTM1337',
    symbol: 'ELITE',
    address: '0xf43Cc235E686d7BC513F53Fbffb61F760c3a1882',
    chainId: 250,
    decimals: 18,
    logoURI: 'https://assets.spookyswap.finance/tokens/ELITE.png',
  },
  {
    name: 'Fantom Oasis Token',
    symbol: 'FTMO',
    address: '0x9bD0611610A0f5133e4dd1bFdd71C5479Ee77f37',
    chainId: 250,
    decimals: 18,
    logoURI: 'https://assets.spookyswap.finance/tokens/FTMO.png',
  },
  {
    name: 'Wootrade Network',
    symbol: 'WOO',
    address: '0x6626c47c00F1D87902fc13EECfaC3ed06D5E8D8a',
    chainId: 250,
    decimals: 18,
    logoURI: 'https://assets.spookyswap.finance/tokens/WOO.png',
  },
  {
    name: 'fSWAMP Token',
    symbol: 'fSWAMP',
    address: '0x23cBC7C95a13071562af2C4Fb1Efa7a284d0543a',
    chainId: 250,
    decimals: 18,
    logoURI: 'https://assets.spookyswap.finance/tokens/fSWAMP.png',
  },
  {
    name: 'Treeb',
    symbol: 'TREEB',
    address: '0xc60D7067dfBc6f2caf30523a064f416A5Af52963',
    chainId: 250,
    decimals: 18,
    logoURI: 'https://assets.spookyswap.finance/tokens/TREEB.png',
  },
  {
    name: 'Morpheus Token',
    symbol: 'MORPH',
    address: '0x0789fF5bA37f72ABC4D561D00648acaDC897b32d',
    chainId: 250,
    decimals: 18,
    logoURI: 'https://assets.spookyswap.finance/tokens/MORPH.png',
  },
  {
    name: 'Iron Finance ICE Token',
    symbol: 'IronICE',
    address: '0x260b3E40c714Ce8196465Ec824Cd8Bb915081812',
    chainId: 250,
    decimals: 18,
    logoURI: 'https://assets.spookyswap.finance/tokens/IronICE.png',
  },
  {
    name: 'Timechain Swap Token',
    symbol: 'TCS',
    address: '0xFbfAE0DD49882e503982f8eb4b8B1e464ecA0b91',
    chainId: 250,
    decimals: 18,
    logoURI: 'https://assets.spookyswap.finance/tokens/TCS.png',
  },
  {
    name: 'FANG Token',
    symbol: 'FANG',
    address: '0x49894fCC07233957c35462cfC3418Ef0CC26129f',
    chainId: 250,
    decimals: 18,
    logoURI: 'https://assets.spookyswap.finance/tokens/FANG.png',
  },
  {
    name: 'Liquid Driver',
    symbol: 'LQDR',
    address: '0x10b620b2dbAC4Faa7D7FFD71Da486f5D44cd86f9',
    chainId: 250,
    decimals: 18,
    logoURI: 'https://assets.spookyswap.finance/tokens/LQDR.png',
  },
  {
    name: 'Geist.Finance Protocol Token',
    symbol: 'GEIST',
    address: '0xd8321AA83Fb0a4ECd6348D4577431310A6E0814d',
    chainId: 250,
    decimals: 18,
    logoURI: 'https://assets.spookyswap.finance/tokens/GEIST.png',
  },
  {
    name: 'Cougar Token',
    symbol: 'CGS',
    address: '0x5a2e451Fb1b46FDE7718315661013ae1aE68e28C',
    chainId: 250,
    decimals: 18,
    logoURI: 'https://assets.spookyswap.finance/tokens/CGS.png',
  },
  {
    name: 'Sing Token',
    symbol: 'SING',
    address: '0x53D831e1db0947c74e8a52618f662209ec5dE0cE',
    chainId: 250,
    decimals: 18,
    logoURI: 'https://assets.spookyswap.finance/tokens/SING.png',
  },
  {
    name: 'Spell Token',
    symbol: 'SPELL',
    address: '0x468003B688943977e6130F4F68F23aad939a1040',
    chainId: 250,
    decimals: 18,
    logoURI: 'https://assets.spookyswap.finance/tokens/SPELL.png',
  },
  {
    name: 'CoffinDollar',
    symbol: 'CoUSD',
    address: '0x0DeF844ED26409C5C46dda124ec28fb064D90D27',
    chainId: 250,
    decimals: 18,
    logoURI: 'https://assets.spookyswap.finance/tokens/CoUSD.png',
  },
  {
    name: 'CoffinToken',
    symbol: 'COFFIN',
    address: '0x593Ab53baFfaF1E821845cf7080428366F030a9c',
    chainId: 250,
    decimals: 18,
    logoURI: 'https://assets.spookyswap.finance/tokens/COFFIN.png',
  },
  {
    name: 'Font',
    symbol: 'FONT',
    address: '0xbbc4A8d076F4B1888fec42581B6fc58d242CF2D5',
    chainId: 250,
    decimals: 18,
    logoURI: 'https://assets.spookyswap.finance/tokens/FONT.png',
  },
]

const FANTOM_TOKEN_LIST = SPOOKY_TOKEN_LIST.reduce((acc, cur) => {
  return {
    ...acc,
    [cur.address]: cur,
  }
}, {})

export default FANTOM_TOKEN_LIST
