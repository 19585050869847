const ETHEREUM_TOKEN_LIST = {
  '0xdd974D5C2e2928deA5F71b9825b8b646686BD200': {
    address: '0xdd974D5C2e2928deA5F71b9825b8b646686BD200',
    symbol: 'KNCL',
    name: 'Kyber Network Crystal Legacy',
    decimals: 18,
    logoURI: 'https://raw.githubusercontent.com/dynamic-amm/dmm-interface/develop/src/assets/images/KNCL.png',
  },
  '0x0ccD5DD52Dee42B171a623478e5261C1eaaE092A': {
    address: '0x0ccD5DD52Dee42B171a623478e5261C1eaaE092A',
    symbol: 'DFM',
    name: 'DFM',
    decimals: 18,
    logoURI: 'https://mycoldwalletnow.wpcomstaging.com/htdocs/wp-content/mcwhp/img/logo.svg',
  },
  '0x6e36556B3ee5Aa28Def2a8EC3DAe30eC2B208739': {
    address: '0x6e36556B3ee5Aa28Def2a8EC3DAe30eC2B208739',
    symbol: 'BUILD',
    name: 'BUILD Finance',
    decimals: 18,
    logoURI: 'https://build.finance/images/logo.png',
  },
  '0x0bfEc35a1A3550Deed3F6fC76Dde7FC412729a91': {
    address: '0x0bfEc35a1A3550Deed3F6fC76Dde7FC412729a91',
    symbol: 'XKNCA',
    name: 'xKNCa',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13995/thumb/Token-4.png?1613545763',
  },
  '0x6710c63432A2De02954fc0f851db07146a6c0312': {
    address: '0x6710c63432A2De02954fc0f851db07146a6c0312',
    symbol: 'MFG',
    name: 'Smart MFG',
    decimals: 18,
    logoURI: 'https://i.imgur.com/oReNLqf.png',
  },
  '0x70e8dE73cE538DA2bEEd35d14187F6959a8ecA96': {
    address: '0x70e8dE73cE538DA2bEEd35d14187F6959a8ecA96',
    symbol: 'XSGD',
    name: 'XSGD',
    decimals: 6,
    logoURI: 'https://assets.website-files.com/600e34cbaf525c42912af8b6/600e34cbaf525c7ad82af99b_xgd-img%20(1).svg',
  },
  '0xBCD515D6C5de70D3A31D999A7FA6a299657De294': {
    address: '0xBCD515D6C5de70D3A31D999A7FA6a299657De294',
    symbol: 'RICE',
    name: 'RICE',
    decimals: 18,
    logoURI: 'https://drive.google.com/uc?export=download&id=16PVXI1Da5P27cywWiyqrcV-Q17u1aXsi',
  },
  '0x0f17BC9a994b87b5225cFb6a2Cd4D667ADb4F20B': {
    address: '0x0f17BC9a994b87b5225cFb6a2Cd4D667ADb4F20B',
    symbol: 'jEUR',
    name: 'Jarvis Synthetic Euro',
    decimals: 18,
    logoURI: 'https://i.imgur.com/92uhfao.png',
  },
  '0x7409856CAE628f5d578B285B45669b36E7005283': {
    address: '0x7409856CAE628f5d578B285B45669b36E7005283',
    symbol: 'jGBP',
    name: 'Jarvis Synthetic British Pound',
    decimals: 18,
    logoURI: 'https://i.imgur.com/HS7BMfs.png',
  },
  '0x53dfEa0A8CC2A2A2e425E1C174Bc162999723ea0': {
    address: '0x53dfEa0A8CC2A2A2e425E1C174Bc162999723ea0',
    symbol: 'jCHF',
    name: 'Jarvis Synthetic Swiss Franc',
    decimals: 18,
    logoURI: 'https://i.imgur.com/Fp31dDB.png',
  },
  '0x7F3EDcdD180Dbe4819Bd98FeE8929b5cEdB3AdEB': {
    chainId: 1,
    address: '0x7F3EDcdD180Dbe4819Bd98FeE8929b5cEdB3AdEB',
    symbol: 'XTK',
    name: 'xToken',
    decimals: 18,
  },
  '0xd7DCd9B99787C619b4D57979521258D1A7267ad7': {
    chainId: 1,
    address: '0xd7DCd9B99787C619b4D57979521258D1A7267ad7',
    symbol: 'EVRY',
    name: 'Evrynet Token',
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11458.png',
  },
  '0x656C00e1BcD96f256F224AD9112FF426Ef053733': {
    chainId: 1,
    address: '0x656C00e1BcD96f256F224AD9112FF426Ef053733',
    symbol: 'EFI',
    name: 'Efinity Token',
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/8985.png',
  },
  '0x0C0F2b41F758d66bB8e694693B0f9e6FaE726499': {
    chainId: 1,
    address: '0x0C0F2b41F758d66bB8e694693B0f9e6FaE726499',
    symbol: 'UND',
    name: 'Unbound Dollar',
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/7848.png',
  },
  '0x431ad2ff6a9C365805eBaD47Ee021148d6f7DBe0': {
    chainId: 1,
    address: '0x431ad2ff6a9C365805eBaD47Ee021148d6f7DBe0',
    symbol: 'DF',
    name: 'dForce',
    decimals: 18,
    logoURI:
      'https://raw.githubusercontent.com/dforce-network/documents/2eea27372fda6a207f60fba4274ad68008d63694/logos/Lending/DF.svg',
  },
  '0x0a5E677a6A24b2F1A2Bf4F3bFfC443231d2fDEc8': {
    chainId: 1,
    address: '0x0a5E677a6A24b2F1A2Bf4F3bFfC443231d2fDEc8',
    symbol: 'USX',
    name: 'dForce USD',
    decimals: 18,
    logoURI:
      'https://raw.githubusercontent.com/dforce-network/documents/2eea27372fda6a207f60fba4274ad68008d63694/logos/Lending/USX.svg',
  },
  '0xb986F3a2d91d3704Dc974A24FB735dCc5E3C1E70': {
    chainId: 1,
    address: '0xb986F3a2d91d3704Dc974A24FB735dCc5E3C1E70',
    symbol: 'EUX',
    name: 'dForce EUR',
    decimals: 18,
    logoURI:
      'https://raw.githubusercontent.com/dforce-network/documents/2eea27372fda6a207f60fba4274ad68008d63694/logos/Lending/EUX.svg',
  },
}

export default ETHEREUM_TOKEN_LIST
